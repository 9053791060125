/*
  Project: hetgoudenhart
  Author: Thomas
 */


.c-hamburger {
  &_bun {
    position: relative;
    display: inline-block;
    width: 1.2rem;
    height: 13px;
    transform: translate(-2px, 1px);
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition-duration: 0.13s;
    transition-delay: 0.13s;
    transition-property: transform;
  }

  &_patty,
  &_patty:before,
  &_patty:after {
    display: block;
    position: absolute;
    width: 1rem;
    height: 0.12rem;
    background-color: $secondary;
    transition-timing-function: ease;
    transition-duration: 0.15s;
    transition-property: transform;
  }

  &_patty {
    top: auto;
    bottom: 0;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition-duration: 0.13s;
    transition-delay: 0.13s;
    transition-property: transform;

    &:before {
      content: '';
      top: -5px;
      transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s, transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    &:after {
      content: '';
      top: -10px;
      transition: top 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s, opacity 0.1s linear;
    }
  }

  &_label {
    height: 13px;
    line-height: 18px;
    display: inline-block;
    margin: 0 0.7rem 0 0.2rem;
    //font-family: $font-;
  }

  &:not(.collapsed) {
    .c-hamburger_bun {
      //margin-top: 0.22rem;
      transform: translate(-2px, 6px);
    }

    .c-hamburger_patty {
      transition-delay: 0.22s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      transform: translate3d(0, -10px, 0) rotate(-45deg);

      &:before {
        top: 0;
        transition: top 0.1s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.16s, transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.25s;
        transform: rotate(-90deg);
      }

      &:after {
        top: 0;
        transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s linear 0.22s;
        opacity: 0;
      }
    }
  }
}
