/*
  Project: hetgoudenhart
  Author: Thomas
 */

.c-search {
  margin-top: 4px;
  &-input {
    border: none;
    padding: 11px 0 4px 30px;
    min-width: 230px;
    width: auto;
    background: url(../assets/images/icon-search.svg) no-repeat left center;
    background-size: 18px auto;

    .c-filtering__search & {
      min-width: 100px;
      transition: $transition-base;

      &:focus {
        min-width: 230px;

        @include media-breakpoint-up(lg) {
          min-width: 300px;
        }
      }
    }
  }
}
