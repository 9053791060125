.u-hover {
  transition: $transition-base;

  &-to-primary {
    &:hover {
      background: $primary !important;
      h3 {
        color: $white !important;
      }
    }
  }

  &-to-secondary {
    &:hover {
      background: $secondary !important;
      h2 {
        color: $white !important;
      }
    }
  }

  &-border {
    border: 2px solid $white;
    &:hover {
      border: 2px solid $secondary;
    }
  }

  &-button-inside {
    &:hover {
      .btn-secondary {
        background: $primary;
        border-color: $primary;
        color: $white;
      }
    }
  }

  &-title-to-primary {
    h2, h3 {
      transition: $transition-base;
    }
    &:hover {
      h2, h3 {
        color: $primary !important;
      }
      .c-action-link span {
        text-decoration: underline;
      }
    }
  }

  &-title-to-secondary {
    h2, h3 {
      transition: $transition-base;
    }
    &:hover {
      h2, h3 {
        color: $secondary !important;
      }
      .c-action-link span {
        text-decoration: underline;
      }
    }

  }

  &-title-to-tertiary {
    h2, h3 {
      transition: $transition-base;
    }
    &:hover {
      h2, h3 {
        color: $tertiary !important;
      }
      .c-action-link span {
        text-decoration: underline;
      }
    }

  }

}
